
import { Component, Mixins, Prop } from 'vue-property-decorator'
import FormField from '@/components/forms/FormField.vue'
import InputText from '@/components/inputs/InputText.vue'
import { FormPage } from '@/mixins'
import MemberImageCard from '@/components/collections/member/MemberImageCard.vue'
import { GetFileModel } from 'tradingmate-modules/src/models/api/files'

@Component({
  components: {
    MemberImageCard,
    InputText,
    FormField
  }
})
export default class MemberGallery extends Mixins(FormPage) {
  private gettingImages = false

  @Prop({ required: true })
  private files: GetFileModel[] | null = null
}
