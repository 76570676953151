
import ContactDetail from '@/components/layout/ContactDetail.vue'
import {
  BusinessBranchModel,
  OpeningTimeDay,
  SocialLinkType
} from 'tradingmate-modules/src/models/api/businesses'
import ContactMethodType from 'tradingmate-modules/src/models/api/ContactMethodType'
import { HttpError, Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import FacebookShare from '@/components/actions/FacebookShare.vue'
import Utils from 'tradingmate-modules/src/Utils'
import FlexRow from '@/components/layout/FlexRow.vue'
import Link from '@/components/layout/Link.vue'
import StarRating from 'vue-star-rating'
// Import Swiper styles
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import Gallery from '@/components/layout/Gallery.vue'
import Copyable from '@/components/inputs/Copyable.vue'
import MapboxMap from '@/components/map/Map.vue'
import InputRating from '@/components/inputs/InputRating.vue'
import BusinessBranchRatingUpsertDto from 'tradingmate-modules/src/models/api/businesses/branches/Rating/BusinessBranchRatingUpsertDto'
import { Config } from 'tradingmate-modules/src/environment'
import Heading from '@/components/typography/Heading.vue'
import Icon from '@/components/typography/Icon.vue'
import FormattedText from '@/components/typography/FormattedText.vue'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { BlogModel, BlogPostStatus } from 'tradingmate-modules/src/models/api/blogs'
import BlogFilter from 'tradingmate-modules/src/filters/BlogFilter'
import BlogCard from '@/components/collections/blogs/BlogCard.vue'
import BusinessBranchAnalyticEventCreateDto from 'tradingmate-modules/src/models/api/analytic-event/BusinessBranchAnalyticEventCreateDto'
import BusinessBranchAnalyticEventType from 'tradingmate-modules/src/models/api/analytic-event/BusinessBranchAnalyticEventType'
import MemberGallery from '@/components/collections/member/MemberGallery.vue'
SwiperCore.use([Navigation, Pagination])

@Component({
  components: {
    MemberGallery,
    ContactDetail,
    FacebookShare,
    Heading,
    FlexRow,
    Link,
    Icon,
    Swiper,
    SwiperSlide,
    Gallery,
    Copyable,
    MapboxMap,
    InputRating,
    StarRating,
    FormattedText,
    BlogCard
  }
})
export default class MemberDetails extends Vue {
  memberSlug = this.$route.params.memberSlug
  member: BusinessBranchModel | null = null
  private loading = false
  private errored = false
  private index: number | null = null
  private consoleUrl = Config.WebsiteUrls.Console

  get galleryImages (): string[] | null {
    const images = this.member?.GalleryImages?.map((img) => { return img.Normal }) ?? null
    return images
  }

  get dateEstablishedYear (): number | null {
    if (this.member === null) return null
    var date = new Date(this.member.DateEstablished)
    var year = date.getFullYear()
    return year
  }

  get logo (): string | null {
    const logo = this.member?.Logo?.Normal ?? null
    return logo
  }

  get descriptor (): string {
    if (!this.member || !this.member.Categories.length) return ''
    const desc = this.member.Categories[0].Name + ' in ' + this.member.MainZone.TMName
    return desc
  }

  async mounted (): Promise<void> {
    await this.GetBranch()
    this.onProfileViewed()
    this.getBlogs()
  }

  async GetBranch (): Promise<void> {
    this.loading = true
    this.errored = false
    await Services.API.Businesses.GetBranchBySlug(this.memberSlug)
      .then(responseData => {
        this.member = responseData
      }).catch(() => {
        this.errored = true
      }).finally(() => {
        this.loading = false
      })
  }

  private pagedBlogs: PagedResultSet<BlogModel> | null = null
  private filter = new BlogFilter({
    Status: BlogPostStatus.Published,
    PageSize: 6
  })

  private loadingBlogs = false
  private errors: HttpError | null = null;

  getBlogs (): void {
    if (this.member === null) return
    this.filter.BusinessBranchId = this.member.BusinessBranchId
    this.loadingBlogs = true
    Services.API.Blogs.GetBlogsForPublic(this.filter)
      .then(returnData => {
        this.pagedBlogs = returnData
      }).catch((errors) => {
        this.errors = errors
      }).finally(() => {
        this.loadingBlogs = false
      })
  }

  private loadingMoreBlogs = false
  showMore (): void {
    this.filter.Page += 1
    this.loadingMoreBlogs = true
    Services.API.Blogs.GetBlogsForPublic(this.filter)
      .then(returnData => {
        if (this.pagedBlogs === null) return
        this.pagedBlogs.Items.concat(returnData.Items)
      }).catch((errors) => {
        this.errors = errors
      }).finally(() => {
        this.loadingMoreBlogs = false
      })
  }

  get hasMoreBlogs (): boolean {
    if (this.pagedBlogs === null) return false
    return (this.pagedBlogs?.Items?.length < this.pagedBlogs?.Count) ?? false
  }

  get mastheadTitle (): string {
    return this.member?.TradingName ?? ''
  }

  get mastheadSubtitle (): string {
    return this.member?.Tagline ?? ''
  }

  get mobileContact (): string | undefined {
    return this.member?.ContactMethods.find(
      contact => contact.ContactMethodType === ContactMethodType.MobilePhone
    )?.Value
  }

  get landlineContact (): string | undefined {
    return this.member?.ContactMethods.find(
      contact => contact.ContactMethodType === ContactMethodType.Landline
    )?.Value
  }

  get emailContact (): string | undefined {
    return this.member?.ContactMethods.find(
      contact => contact.ContactMethodType === ContactMethodType.Email
    )?.Value
  }

  getLinkIcon (socialLinkType: SocialLinkType): string {
    switch (socialLinkType) {
      case SocialLinkType.Facebook:
        return '/assets/icons/social/facebook.svg'
      case SocialLinkType.Website:
        return '/assets/icons/social/website.svg'
      case SocialLinkType.Instagram:
        return '/assets/icons/social/instagram.svg'
      case SocialLinkType.Linkedin:
        return '/assets/icons/social/linkedin.png'
      case SocialLinkType.GoogleMyBusiness:
        return '/assets/icons/social/google-my-business.svg'
      case SocialLinkType.Video:
        return '/assets/icons/social/video.svg'
      case SocialLinkType.Twitter:
        return '/assets/icons/social/twitter.svg'
      case SocialLinkType.TripAdvisor:
        return '/assets/icons/social/tripadvisor.svg'
      case SocialLinkType.Pinterest:
        return '/assets/icons/social/pinterest.svg'
      case SocialLinkType.Etsy:
        return '/assets/icons/social/etsy.svg'
      default:
        return ''
    }
  }

  getLinkName (socialLinkType: SocialLinkType): string {
    switch (socialLinkType) {
      case SocialLinkType.Facebook:
        return 'Facebook'
      case SocialLinkType.Website:
        return 'Website'
      case SocialLinkType.Instagram:
        return 'Instagram'
      case SocialLinkType.Linkedin:
        return 'Linkedin'
      case SocialLinkType.GoogleMyBusiness:
        return 'Google My Business'
      case SocialLinkType.Video:
        return 'Video'
      case SocialLinkType.Twitter:
        return 'Twitter'
      case SocialLinkType.TripAdvisor:
        return 'TripAdvisor'
      case SocialLinkType.Pinterest:
        return 'Pinterest'
      case SocialLinkType.Etsy:
        return 'Etsy'
      default:
        return ''
    }
  }

  getOpeningHours (openingTimeDay: OpeningTimeDay): string | null {
    const openingHours = this.member?.OpeningTimes.find(
      time => time.Day === openingTimeDay
    )
    if (openingHours) {
      return (
        Utils.isoToDisplayTime(openingHours.OpeningTime) + ' - ' +
        Utils.isoToDisplayTime(openingHours.ClosingTime)
      )
    } else {
      return null
    }
  }

  formattedTime (hours: number, minutes: number): string {
    const formattedHours = (hours > 12 ? hours - 12 : hours).toString()
    const isPM = hours >= 12
    const formattedMinutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
    return formattedHours + ':' + formattedMinutes + (isPM ? 'pm' : 'am')
  }

  getSocialLink (socialLinkType: SocialLinkType): string | undefined {
    return this.member?.SocialLinks.find((link) => link.LinkType === socialLinkType)?.Link
  }

  private upsertingRating = false
  async updateRating (rating: number): Promise<void> {
    // const credential = Services.CredentialManager.GetOAuthCredential()
    console.log(rating)
    // Uses cookie session id

    const dto: BusinessBranchRatingUpsertDto = {
      Rating: rating
    }
    console.log(dto)

    if (this.member === null) return

    this.upsertingRating = true
    try {
      const responseData = await Services.API.Businesses.UpsertRating(this.member.BusinessBranchId, dto)
      if (!responseData.IsSignedIn) {
        window.open(this.consoleUrl + '/account/login', '_self')
      }
      if (!this.member) return
      this.member.RatingCount++
      this.member.UserRating = responseData.UserRating
      this.member.AverageRating = responseData.NewAverageRating
    } catch (error) {
      this.errored = true
      console.log(error)
    } finally {
      this.upsertingRating = false
    }
  }

  onProfileViewed (): void {
    console.log('oN profile views')
    if (this.member === null) return
    var dto: BusinessBranchAnalyticEventCreateDto = { Type: BusinessBranchAnalyticEventType.ProfileView }
    Services.API.AnalyticEvent.CreateBusinessBranchAnalyticEvent(this.member.BusinessBranchId, dto)
  }
}

