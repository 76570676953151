
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class InputRating extends Vue {
  private inactiveStar = '/assets/icons/inactive.png'
  private activeStar = '/assets/icons/active.png'

  private userHover = 3

  @Prop({ required: true })
  private readonly rating!: number;

  @Prop({ required: true })
  private readonly userRating!: number | null;

  // changeUserRating (rating: number): void {
  //   // emit the rating
  // }

  getSrc (index: number): string {
    if (this.userRating === null) {
      return '/assets/icons/inactive.png'
    }
    if (index <= this.userRating) {
      return '/assets/icons/active.png'
    } else {
      return '/assets/icons/inactive.png'
    }
  }

  get overlayPercentage (): number {
    return (this.rating / 5) * 100
  }
}

